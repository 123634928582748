<template>
  <div>students-attendance-report</div>
</template>

<script>
export default {
  name: "StudentsAttendanceReport"
}
</script>

<style scoped>
</style>